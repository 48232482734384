import { formatVehicleType } from '@superdispatch/sdk';
import { useFormikContext } from 'formik';
import React from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { OrderFormVehicleDTO, VehicleModel } from '../data/OrderFormVehicleDTO';
import { useVehicleModels } from '../data/OrderVehicleAPI';
import { VehicleBaseAutocomplete } from './VehicleBaseAutocomplete';

interface VehicleModelAutocompleteProps {
  name: string;
  disabled?: boolean;
  vehicle: OrderFormVehicleDTO;
  isVinValid: boolean;
}

const formatModelNames = (options: VehicleModel[]): VehicleModel[] => {
  return options.map((option: VehicleModel) => {
    let modifiedOption = { ...option };

    if (
      options.filter((item: VehicleModel) => item.name === option.name).length >
      1
    ) {
      modifiedOption.display_name = `${option.name} (${formatVehicleType(
        option.vehicle_type,
      )})`;
    }

    return modifiedOption;
  });
};

export function VehicleModelAutocomplete({
  name,
  vehicle,
  isVinValid,
  disabled,
}: VehicleModelAutocompleteProps) {
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue } = useFormikContext();
  const isVehicleImprovementEnabled = useFeatureToggle(
    'order-vehicle-improvements.access.ui',
  );
  const { data } = useVehicleModels(
    inputValue,
    vehicle.make,
    vehicle.year,
    !!(
      (!vehicle.vin || (vehicle.vin && !isVinValid)) &&
      (vehicle.year || isVehicleImprovementEnabled) &&
      vehicle.make &&
      inputValue.trim().length > 0
    ),
  );

  const options = formatModelNames(data?.objects || []);

  return (
    <VehicleBaseAutocomplete
      disabled={disabled}
      name={`${name}.model`}
      listBoxLabel="vehicle model list"
      options={options}
      setInputValue={setInputValue}
      onChangeProp={(newValue) => {
        const value = newValue as VehicleModel;
        setFieldValue(
          `${name}.model`,
          typeof value === 'string' ? value : value.name,
        );

        if (typeof value !== 'string' && value.vehicle_type) {
          setFieldValue(`${name}.type`, value.vehicle_type);
        }
      }}
    />
  );
}

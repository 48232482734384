import { useFormikContext } from 'formik';
import React from 'react';
import { useRecentSelections } from 'shared/helpers/RecentSelectionsHelpers';
import { OrderFormVehicleDTO } from '../data/OrderFormVehicleDTO';
import { useVehicleMakers } from '../data/OrderVehicleAPI';
import { VehicleBaseAutocomplete } from './VehicleBaseAutocomplete';

interface VehicleMakeAutocompleteProps {
  name: string;
  disabled?: boolean;
  vehicle: OrderFormVehicleDTO;
  isVinValid: boolean;
}

export function VehicleMakeAutocompleteLegacy({
  name,
  disabled,
  vehicle,
  isVinValid,
}: VehicleMakeAutocompleteProps) {
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue } = useFormikContext();
  const recentSelections = useRecentSelections();
  const recentOptions = recentSelections.vehicle_makers || [];

  const { data } = useVehicleMakers(
    inputValue,
    !!(
      (!vehicle.vin || (vehicle.vin && !isVinValid)) &&
      inputValue.trim().length >= 2
    ),
  );

  const options = data?.objects || [];

  return (
    <VehicleBaseAutocomplete
      name={name}
      disabled={disabled}
      listBoxLabel="vehicle make list"
      setInputValue={setInputValue}
      onChangeProp={(value) => {
        setFieldValue(name, typeof value === 'string' ? value : value.name);
      }}
      options={
        options.length > 0
          ? options
          : inputValue.trim().length < 3
          ? recentOptions
          : []
      }
    />
  );
}

import { renderChildren } from '@superdispatch/ui';
import { InferType } from 'prop-types';
import { ReactNode } from 'react';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { WhoAmI } from 'shared/types/user';
import { yupBoolean, yupObject } from 'shared/utils/YupUtils';
import { readStorageItem } from '../helpers/LocalStorage';
import { useUserState } from './AppUserState';

// Please sort alphabetically.
type FeatureToggle =
  | 'ach-payment.access.ui'
  | 'auto-repost-recommendation.enabled.ui'
  | 'build-loads.enabled.ui'
  | 'build-loads.split-order.enabled.ui'
  | 'carrier-invoice.po-number-and-carrier-invoice-date.enabled'
  | 'carrier-requests-superpay.access.ui'
  | 'cd-integration.import-post-listings'
  | 'cd-integration-web-2fa.enabled'
  | 'compliance.restrict-ratings-for-unverified-shippers.enabled'
  | 'cx-loadboard-issues-dialog.enabled'
  | 'frontend.two-factor-authentication.sms.enabled'
  | 'instant-dispatch.access.ui'
  | 'match-payment-terms-to-methods.enabled.ui'
  | 'order-faster-booking-recommendations.enabled.ui'
  | 'order-vehicle-improvements.access.ui'
  | 'payment_method_terms_adoption.enabled.ui'
  | 'payments.expedited-pay.access.ui'
  | 'payments.expedited.pay.carrier.eligibility.access.ui'
  | 'price-prediction.access.ui'
  | 'restricted-w9.enabled.ui'
  | 'self-serve-cancellation-reasons.enabled.ui'
  | 'sso.enabled.ui'
  | 'subscription-past-due-payment.enabled'
  | 'split-order.enabled.ui'
  | 'stms.clean-username-when-user-deactivates.enabled'
  | 'stms.product-onboarding-v1.enabled'
  | 'stms.system.delivery.verification.enabled'
  | 'update-credit-card-info.enabled'
  | 'verification-application.international-shippers.enabled.ui'
  | 'verification-optimization.enabled.ui'
  | 'subscription-details-v2.ui'
  | 'stms.escalation.enable'
  | 'stms.refresh-token.enabled'
  | 'system-alerts.ui'
  | 'temp-rxo-paywall.enabled'
  | 'order-form.reinitialize.ui'
  | 'refresh-token-logs.ui';

export function getFeatureToggle(
  user: WhoAmI | undefined,
  feature: FeatureToggle,
) {
  if (import.meta.env.MODE !== 'production') {
    const storageValue = readStorageItem(`features:${feature}`);

    if (storageValue != null) {
      return storageValue === 'true';
    }
  }

  return user?.feature_toggles?.[feature] ?? true;
}

export function useFeatureToggle(feature: FeatureToggle) {
  const { user } = useUserState();

  return getFeatureToggle(user, feature);
}

interface FeatureToggleProps {
  name: FeatureToggle;
  children: ReactNode;
  fallback?: ReactNode;
}

export function FeatureToggle({
  name,
  children,
  fallback,
}: FeatureToggleProps) {
  const enabled = useFeatureToggle(name);
  return renderChildren(enabled ? children : fallback);
}

const isFeatureToggleEnabledSchema = yupObject({
  is_enabled: yupBoolean(),
});

type IsFeatureToggleEnabledDTO = InferType<typeof isFeatureToggleEnabledSchema>;

export function useFeatureTogglePublic(code: FeatureToggle) {
  const { requestResource } = useAPI();

  return useAPIQuery(
    ['feature_toggles', 'enabled', { code }],
    () =>
      requestResource<IsFeatureToggleEnabledDTO>(
        'GET /features/{code}',
        (data) => isFeatureToggleEnabledSchema.cast(data),
        { code },
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
}

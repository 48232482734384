import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button, ButtonArea, TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { CustomerSupportButtonSwitcher } from 'shared/helpers/customer-support/CustomerSupportButtonSwitcher';
import { trackRatingSubmitEvent } from './data/CarrierRatingAnalytics';
import { useCarrier, useCarrierRatingAPI } from './data/CarrierRatingAPI';
import {
  CarrierRatingTag,
  CarrierRatingType,
  formatRatingTag,
  RateCarrierPayloadDTO,
} from './data/CarrierRatingDTO';

export function CarrierRatingTagField({ tag }: { tag: CarrierRatingTag }) {
  return <FormikCheckboxArrayField name={tag} label={formatRatingTag(tag)} />;
}

interface CarrierRatingDialogContentProps {
  onClose: () => void;
  onSubmitSuccess: () => void;
  carrierGuid: string;
}

function CarrierRatingDialogContent({
  carrierGuid,
  onClose,
  onSubmitSuccess,
}: CarrierRatingDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();

  const { rateCarrier } = useCarrierRatingAPI();
  const { data: carrier } = useCarrier(carrierGuid);

  const initialValues = useMemo(() => {
    const initialRating = carrier?.broker_records.carrier_rating;
    return {
      rating: initialRating?.rating ?? null,
      comment: initialRating?.comment ?? null,
      tags: initialRating?.tags ?? [],
    };
  }, [carrier?.broker_records.carrier_rating]);

  const formik = useFormikEnhanced({
    initialValues,
    onSubmit: (values) =>
      rateCarrier(carrierGuid, values as RateCarrierPayloadDTO),
    onSubmitSuccess(_, values) {
      trackRatingSubmitEvent(values as RateCarrierPayloadDTO);
      addSnackbar('Rating submitted', { variant: 'success' });
      onSubmitSuccess();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
    validate: (values) => {
      if (
        values.rating === 'NEGATIVE' &&
        !!values.tags &&
        values.tags.length < 1
      ) {
        return { tags: 'Select at least one reason' };
      }
      return {};
    },
  });

  const {
    setFieldValue,
    values: { rating },
  } = formik;

  function updateRating(value: CarrierRatingType) {
    // Flush tags between POSITIVE and NEGATIVE rating
    setFieldValue('tags', []);
    setFieldValue('rating', value);
  }

  const hasTagsError = formik.touched.tags && formik.errors.tags;

  return (
    <FormikProvider value={formik}>
      <DialogTitle>{carrier?.name}</DialogTitle>

      <DialogContent style={{ marginTop: '-8px' }}>
        <Box marginBottom="small">
          <Stack space="medium">
            <TextBox color="secondary">
              Learn more about ratings{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                href="https://support.superdispatch.com/hc/articles/35661788289555-Ratings-in-Super-Dispatch"
              >
                here
              </Link>
              .
            </TextBox>

            <Stack space="small">
              <TextBox variant="heading-4">Rate your recent experience</TextBox>

              <Columns space="small">
                <Column>
                  <ButtonArea
                    fullWidth={true}
                    variant="danger"
                    active={rating === 'NEGATIVE'}
                    onClick={() => updateRating('NEGATIVE')}
                    icon={<ThumbDown />}
                  >
                    Negative
                  </ButtonArea>
                </Column>

                <Column>
                  <ButtonArea
                    fullWidth={true}
                    icon={<ThumbUp />}
                    variant="success"
                    active={rating === 'POSITIVE'}
                    onClick={() => updateRating('POSITIVE')}
                  >
                    Positive
                  </ButtonArea>
                </Column>
              </Columns>
            </Stack>

            {rating === 'POSITIVE' && (
              <Stack space="medium">
                <Stack space="small">
                  <TextBox variant="heading-4">What went well?</TextBox>

                  <FormikCheckboxArrayGroup name="tags">
                    <Stack space="none">
                      <CarrierRatingTagField tag="ON_TIME_TRANSPORT" />
                      <CarrierRatingTagField tag="GOOD_COMMUNICATION" />
                      <CarrierRatingTagField tag="GOOD_CUSTOMER_SERVICE" />
                    </Stack>
                  </FormikCheckboxArrayGroup>
                </Stack>

                <FormikMaxLengthTextField
                  rows={3}
                  maxLength={280}
                  name="comment"
                  multiline={true}
                  fullWidth={true}
                  helperText="Publicly visible."
                  label={
                    <TextBox color="secondary">Tell more about it</TextBox>
                  }
                  placeholder="Driver was nice, demonstrated experience…"
                />
              </Stack>
            )}
            {rating === 'NEGATIVE' && (
              <Stack space="medium">
                {initialValues.rating && (
                  <Box
                    padding="xsmall"
                    borderRadius="small"
                    backgroundColor="Yellow50"
                  >
                    Update will set the 10-day Pending period.
                  </Box>
                )}
                <Stack space="small">
                  <Stack space="none">
                    <TextBox variant="heading-4">What went wrong?</TextBox>
                    <TextBox
                      variant="caption"
                      color={hasTagsError ? 'red' : 'secondary'}
                    >
                      {hasTagsError ? 'Select reason below' : 'Required'}
                    </TextBox>
                  </Stack>

                  <FormikCheckboxArrayGroup name="tags">
                    <Stack space="none">
                      <CarrierRatingTagField tag="LATE_TRANSPORT" />
                      <CarrierRatingTagField tag="DAMAGE" />
                      <CarrierRatingTagField tag="BAD_COMMUNICATION" />
                      <CarrierRatingTagField tag="BAD_CUSTOMER_SERVICE" />
                      <CarrierRatingTagField tag="LATE_CANCELLATION_OR_NO_SHOW" />
                      <CarrierRatingTagField tag="DOUBLE_BROKERING" />
                    </Stack>
                  </FormikCheckboxArrayGroup>
                </Stack>

                <FormikMaxLengthTextField
                  rows={3}
                  maxLength={280}
                  name="comment"
                  multiline={true}
                  fullWidth={true}
                  helperText="Publicly visible."
                  label={
                    <TextBox color="secondary">Tell more about it</TextBox>
                  }
                  placeholder="What disappointed you the most?
What happened?"
                />
              </Stack>
            )}
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Inline space="small">
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!rating}
            variant="primary"
            pending={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </Button>
        </Inline>
      </DialogActions>
    </FormikProvider>
  );
}

interface CarrierRatingDialogProps extends CarrierRatingDialogContentProps {
  open: boolean;
}

export function CarrierRatingDialog({
  open,
  onClose,
  ...props
}: CarrierRatingDialogProps) {
  const screen = useResponsiveValue('mobile', 'other');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth={true}
      transitionDuration={{ exit: 0 }}
      fullScreen={screen === 'mobile'}
    >
      <CustomerSupportButtonSwitcher />

      <CarrierRatingDialogContent {...props} onClose={onClose} />
    </Dialog>
  );
}

import { Link, Typography } from '@material-ui/core';
import {
  ALL_PAYMENT_METHODS,
  ALL_PAYMENT_TERMS,
  VEHICLE_TYPES,
} from '@superdispatch/sdk';
import { ColorDynamic, Inline, Stack } from '@superdispatch/ui';
import { Box, DescriptionLineItem } from '@superdispatch/ui-lab';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import styled from 'styled-components';

const InstructionLabel = styled(Typography)`
  color: ${ColorDynamic.Dark300};
`;

const InstructionValue = styled.div`
  inline-size: 368px;
  overflow-wrap: break-word;
  text-align: right;
`;

export function OrderImportInstructions() {
  const { user } = useUserState();
  const { isAdvancedTier } = useProductTiers();

  function getTemplateLink() {
    if (user?.shipper.shipper_type === 'CUSTOMER') {
      return 'https://storage.googleapis.com/prod-broker-files/order-import-template-customer.csv';
    }
    if (isAdvancedTier) {
      return 'https://storage.googleapis.com/prod-broker-files/import-template.csv';
    }
    return 'https://storage.googleapis.com/prod-broker-files/basic_tier-import-template.csv';
  }

  return (
    <Box maxWidth="752px" width="100%">
      <Stack space="small">
        <Inline space="small" verticalAlign="center">
          <Typography variant="h3">Requirements</Typography>
          <Link href={getTemplateLink()} color="primary">
            Download sample file
          </Link>
        </Inline>
        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Dates</Typography>
              <InstructionLabel>
                should be in the following format
              </InstructionLabel>
            </Inline>
          }
        >
          MM/DD/YY or MM/DD/YYYY
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Pickup Date Type</Typography>
              <InstructionLabel>and</InstructionLabel>
              <Typography variant="h5">Delivery Date Type</Typography>
              <InstructionLabel>could be</InstructionLabel>
            </Inline>
          }
        >
          estimated, exact, not_later_than, not_earlier_than
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Pickup State</Typography>
              <InstructionLabel>and</InstructionLabel>
              <Typography variant="h5">Delivery State</Typography>
              <InstructionLabel>should be</InstructionLabel>
            </Inline>
          }
        >
          2-letter abbreviation
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Vehicle Type</Typography>
              <InstructionLabel>could be</InstructionLabel>
            </Inline>
          }
        >
          <InstructionValue>
            {VEHICLE_TYPES.map((vehicleType) => vehicleType).join(', ')}
          </InstructionValue>
        </DescriptionLineItem>
        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Carrier Payment Methods</Typography>
              <InstructionLabel>could be</InstructionLabel>
            </Inline>
          }
        >
          <InstructionValue>
            {ALL_PAYMENT_METHODS.map((paymentMethod) => paymentMethod).join(
              ', ',
            )}
          </InstructionValue>
        </DescriptionLineItem>

        <DescriptionLineItem
          title={
            <Inline space="xxsmall">
              <Typography variant="h5">Carrier Payment Terms</Typography>
              <InstructionLabel>could be</InstructionLabel>
            </Inline>
          }
        >
          <InstructionValue>
            {ALL_PAYMENT_TERMS.filter(
              (term) =>
                term !== 'ach' && // deprecated
                term !== 'quick_pay' && // deprecated
                term !== 'comchek', // deprecated
            ).join(', ')}
          </InstructionValue>
        </DescriptionLineItem>
      </Stack>
    </Box>
  );
}

import { VehicleType } from '@superdispatch/sdk';
import {
  Exclude,
  Expose,
  plainToClass,
  Transform,
  Type,
} from 'class-transformer';
import { DecodedOrderVehicleDTO } from './DecodedOrderVehicleDTO';

@Exclude()
class OrderFormVehicleVenueDTO {
  @Expose() completed_at: string | null;
}

@Exclude()
export class OrderFormVehiclePhotoDTO {
  @Expose() id?: number;
  @Expose() guid?: string;
  @Expose() photo_url?: string;
  @Expose() thumbnail_url?: string;
  @Expose() photo_type: 'Sample' | 'Delivery' | 'Pickup';

  // Prevent Using File constructor for transforming
  @Type(() => class {})
  @Transform((_, { photo_file }) => photo_file)
  @Expose({ toClassOnly: true })
  photo_file?: File;
}

export class OrderFormVehicleDTO {
  static create(values: unknown) {
    return plainToClass(OrderFormVehicleDTO, values);
  }

  static mergeDecodedVehicle(
    vehicle: OrderFormVehicleDTO,
    newVehicle: DecodedOrderVehicleDTO,
  ) {
    const {
      vin,
      year,
      curb_weight,
      curb_weight_unit,
      make,
      model,
      color,
      type,
    } = newVehicle;
    return this.create({
      ...vehicle,
      vin,
      year,
      curb_weight,
      curb_weight_unit,
      make,
      model,
      color,
      type,
    });
  }

  @Expose()
  @Transform((value, obj) => value || obj.id || Math.random())
  key: number; // needed for react key

  @Expose() id?: number;
  @Expose() vin?: string;
  @Expose() status?: null;
  @Expose() is_modified?: boolean;
  @Expose() guid?: string;
  @Expose() is_inoperable?: boolean;
  @Expose() pickup?: OrderFormVehicleVenueDTO;
  @Expose() delivery?: OrderFormVehicleVenueDTO;
  @Expose() make: string;
  @Expose() model: string;
  @Expose() color?: string;
  @Expose() requires_enclosed_trailer?: boolean;
  @Expose() type?: string;
  @Expose() year?: string;
  @Expose() jtracker_id?: number;
  @Expose() lot_number?: string;
  @Expose() tariff?: number;
  @Expose() price?: number;
  @Expose() is_damaged?: boolean;
  @Expose() is_active?: boolean;
  @Expose() pdf_bol_url: string | null;

  @Expose() curb_weight?: number;
  @Expose() curb_weight_unit?: string;
  // Dimensions fields are unpopulated on backend
  @Exclude() length?: string;
  @Exclude() width?: string;
  @Exclude() height?: string;

  @Expose()
  @Type(() => OrderFormVehiclePhotoDTO)
  photos?: OrderFormVehiclePhotoDTO[] | null;
}

export interface VehicleMake {
  name: string;
  display_name?: string;
}

export interface VehicleModel {
  name: string;
  display_name?: string;
  vehicle_type: VehicleType;
}

export const POPULAR_VEHICLE_MAKES = [
  'Acura',
  'Audi',
  'BMW',
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ford',
  'Genesis',
  'GMC',
  'Honda',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Jeep',
  'Kia',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Mazda',
  'Mercedes-Benz',
  'Mini',
  'Mitsubishi',
  'Nissan',
  'Porsche',
  'RAM',
  'Subaru',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
];

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { Stack, useResponsiveValue, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { useShipperRatingResponse } from './data/ShipperProfileAPI';
import { ShipperRatingDTO } from './data/ShipperRatingDTO';

interface Props {
  open: boolean;
  rating: ShipperRatingDTO;
  onClose: () => void;
}
export function ShipperRatingResponseDialog({ open, rating, onClose }: Props) {
  const screen = useResponsiveValue('mobile', 'desktop');
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      fullScreen={screen === 'mobile'}
    >
      <ResponseDialogContent rating={rating} onClose={onClose} />
    </Dialog>
  );
}

interface DialogProps {
  rating: ShipperRatingDTO;
  onClose: () => void;
}
function ResponseDialogContent({ rating, onClose }: DialogProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateRatingResponse } = useShipperRatingResponse();

  const formik = useFormikEnhanced({
    initialValues: { response: rating.response ?? '' },
    onSubmit: (values) => updateRatingResponse(rating.guid, values),
    onSubmitSuccess: () => {
      onClose();
      addSnackbar(
        rating.response === null ? 'Response added' : 'Response updated',
        { variant: 'success' },
      );
    },
    onSubmitFailure(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <DialogTitle>
        {rating.response === null ? 'Response to Rating' : 'Update Response'}
      </DialogTitle>
      {rating.status === 'PUBLISHED' ? (
        <DialogContent>
          <FormikMaxLengthTextField
            aria-label="response"
            rows={4}
            maxLength={280}
            name="response"
            multiline={true}
            fullWidth={true}
          />
        </DialogContent>
      ) : (
        <DialogContent style={{ marginTop: '-8px' }}>
          <Stack space="medium">
            <TextBox variant="body" color="secondary">
              Response will be visible only for you and for the Carrier.
            </TextBox>
            <FormikMaxLengthTextField
              aria-label="response"
              rows={4}
              maxLength={280}
              name="response"
              multiline={true}
              fullWidth={true}
            />
            <Box
              padding="xsmall"
              borderRadius="small"
              backgroundColor="Yellow50"
            >
              Responses will not be publicly visible until the 10 day pending
              period has ended.
            </Box>
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="neutral"
          onClick={onClose}
          disabled={formik.isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          disabled={
            !rating.guid || !formik.dirty || !formik.values.response.trim()
          }
        >
          {rating.response === null ? 'Add Response' : 'Update'}
        </Button>
      </DialogActions>
    </FormikProvider>
  );
}

import { useFormikContext } from 'formik';
import React from 'react';
import { useRecentSelections } from 'shared/helpers/RecentSelectionsHelpers';
import {
  OrderFormVehicleDTO,
  POPULAR_VEHICLE_MAKES,
} from '../data/OrderFormVehicleDTO';
import { useVehicleMakers } from '../data/OrderVehicleAPI';
import { VehicleBaseAutocomplete } from './VehicleBaseAutocomplete';

interface VehicleMakeAutocompleteProps {
  name: string;
  disabled?: boolean;
  vehicle: OrderFormVehicleDTO;
  isVinValid: boolean;
}

export function VehicleMakeAutocomplete({
  name,
  disabled,
  vehicle,
  isVinValid,
}: VehicleMakeAutocompleteProps) {
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue } = useFormikContext();
  const recentSelections = useRecentSelections();
  const recentOptions = recentSelections.vehicle_makers || [];

  const { data } = useVehicleMakers(
    inputValue,
    !!((!vehicle.vin || (vehicle.vin && !isVinValid)) && inputValue),
  );

  const options = data?.objects || [];

  return (
    <VehicleBaseAutocomplete
      name={name}
      disabled={disabled}
      listBoxLabel="vehicle make list"
      setInputValue={setInputValue}
      onChangeProp={(value) => {
        setFieldValue(name, typeof value === 'string' ? value : value.name);
      }}
      options={
        !inputValue
          ? Array.from([
              { name: 'Popular Makes' },
              ...POPULAR_VEHICLE_MAKES.map((make) => ({ name: make })),
            ])
          : options.length
          ? options
          : recentOptions
      }
      getOptionDisabled={(option) => {
        if (typeof option === 'string') {
          return option === 'Popular Makes';
        }
        return option.name === 'Popular Makes';
      }}
    />
  );
}
